<template>
<br><br>
  <form @submit.prevent="handleSubmit">
      <h3>Login</h3>
      <input type="email" placeholder="email" v-model="email">
      <input type="password" placeholder="password" v-model="password">
      <div v-if="error" class="error">{{ error }}</div>
      <button  class="btn" v-if="!isPending">Login</button>
      <button v-if="isPending" disabled>Loading..</button>

  </form><br>

      <div class="textsection" style="max-width:400px">
          <p><strong>OR</strong> sign in with:</p>
           
            <img style="cursor:pointer;" @click="socialLogin" src="/images/glogo1.png" alt="Sign in with Google logo">
     
      </div>
      




</template>

<script>
import useLogin from '@/composables/useLogin'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import firebase from 'firebase';
import getUser from '@/composables/getUser'

    import useCollection from '@/composables/useCollection';
    import getPeopleFaves from '@/composables/getPeopleFaves'


export default {
    setup(){
       const{error, login, isPending} = useLogin()
       const {peopleerror, addPerson} = useCollection('people')

       const { user } = getUser()


       const router = useRouter()
       const email = ref('')
       const password = ref('')
 
       const displayName = ref('')

       const handleSubmit = async () => {
           const res = await login (email.value,  password.value)
           if(!error.value){
               router.push( {name: 'UserGalleries'} )
           } else {
               alert('Oops.. ' + error.value)
           }
       }

       const socialLogin = async () => {
           const provider = new firebase.auth.GoogleAuthProvider();

              const response = await firebase.auth().signInWithPopup(provider)
              var {errorpeople, document1: people } = getPeopleFaves('people', user.value.uid)
              //alert(people);
              if(!errorpeople){
                handleCreatePeople();  
              } else {
                  router.push({ name: 'UserGalleries'})
              }
                

            // firebase.auth().signInWithPopup(provider).then((result) => {
            //     //this.$router.replace('home')
            //     router.push( {name: 'UserGalleries'} )
            // }).catch((error) => {
            //     alert('Oops. ' + error.message)
            // })
       }


               const handleCreatePeople = async () => {
                   
                    const res = await addPerson({
                        
                        userId: user.value.uid,
                        userName: displayName.value,
                        following:[],
                        faves:[],
                        galleries:[]
                    })
                    //isPending.value = false
                    if(!peopleerror.value){
                        router.push({ name: 'UserGalleries'})
                    } else{
                        console.log('error!!!1')
                    }
                
             
            
                }




       return {email, password, handleSubmit, socialLogin, error, isPending, handleCreatePeople}
    }
}
</script>

<style>

input, button{
	margin: 5% !important;
	
}


</style>