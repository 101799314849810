<template>
  <div class="home container" >
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <router-link :to=" {name: 'CreateGallery'} " class="btn bg-white">Create a new Gallery</router-link> -->
    
    <div class="textsection">
         <br><br><h2><span style="color:#da0f41;">About</span></h2>

        <p>We want to help people to showcase their lives, and learn about others, by curating and sharing their art.</p>  
        <p>INSTANT.GALLERY allows you to setup a digital gallery in seconds. Our creators instantly share and discover photos that can be viewed on mobile, desktop and in VR.</p>
        
        <p>Being web first provides the abilty to work across devices, and VR technologies allows to break our photos out from our phones and see what our images look like when displayed up to (and beyond!) life size.</p>

        <p>Built with, and a special thanks to the teams who create and maintain: <a href="https://aframe.io/" target="blank">A-FRAME</a>, <a href="https://threejs.org//" target="blank">threejs</a>, and <a href="https://vuejs.org//" target="blank">Vue.js</a>.</p>

       

        <br><h4><span style="color:#da0f41;">Using the Virtual Joystick to move through a gallery</span></h4>

        <iframe width="auto" height="315" src="https://www.youtube.com/embed/XuDSblFwpMA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

         <br><br> <a href="https://www.privacypolicies.com/live/d1563c91-d78b-4e6d-9193-d41e2973d5d7" target="blank">Privacy Policy</a>

       
    </div>
   
  </div>
</template>

<script>
import ListView from '../components/ListView.vue'
import getCollection from '../composables/getCollection'




export default {
  name: 'Home',
  components: {ListView},
  setup() {
    const { error, documents } = getCollection('galleries')
    // console.log('home')
    // console.log(documents)

    return {error, documents}
  }
}
</script>

<style scoped>


  
</style>
