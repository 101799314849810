<template>

  
   


<div v-if="user" class="myNavBar">

   <!-- <router-link to="/" >
   <button class=" btn btn-sm floatingbutton responsiveText" style="background:#b6c199;">instant.gallery</button>
   
   </router-link> -->

   <button class="btn btn-sm  floatingbutton" @click="openNav">&#9776; Menu</button>

   

    <router-link :to="{ name: 'CreateGallery'}" >
    <button class=" btn btn-sm floatingbutton" >Create Gallery</button>
   </router-link>

   <router-link to="/" >
    <button class=" btn btn-sm  floatingbutton" >Explore</button>
   </router-link>
</div>

<div v-if="!user" class="myNavBar" >
   <!-- <router-link to="/" >
   <button class=" btn btn-sm floatingbutton responsiveText" >instant.gallery</button>
   
   </router-link> -->

   <button class="btn btn-sm  floatingbutton" @click="openNav">&#9776; Menu</button>

    <router-link :to="{ name: 'Signup' }" >
    <button class=" btn btn-sm floatingbutton responsiveText" >Signup</button>
   </router-link>

   <router-link :to="{ name: 'Login' }" >
    <button class=" btn btn-sm floatingbutton responsiveText" >Login</button>
   </router-link>
</div>


 <div  id="mySidebar" class="sidebar">
            <a href="javascript:void(0)" class="closebtn" @click="closeNav">&times;</a>
            <router-link to="/" @click="closeNav" >Home</router-link>
            <!-- <router-link :to="{ name: 'Upgrade' }" @click="closeNav" v-if="user" href="#">Upgrade</router-link> -->
            <router-link :to="{ name: 'AccountPage' }" @click="closeNav" v-if="user" href="#">Account</router-link>
            <router-link :to="{ name: 'UserGalleries' }" @click="closeNav" v-if="user" href="#">My&nbsp;Galleries</router-link>
            <router-link :to="{ name: 'About' }" @click="closeNav"  href="#">About</router-link>
           <router-link :to="{ name: 'Contact' }" @click="closeNav" href="#">Contact</router-link>
            <a v-if="user" href="#" @click="handleClick">Logout</a>
          </div>
<!-- 
          router.push( {name: 'UserGalleries'} ) -->

          <!-- <div v-if="user" id="main">
            <button class="openbtn" @click="openNav">&#9776; INSTANT.GALLERY</button>
           
          </div> -->
<!-- <i class="fas fa-home" style="font-size:33px;color:#c9011a;"></i> -->


  <!-- <i id="home" class="fas fa-home" style="color:#c9011a; "></i> -->
 <!-- <MDBBtn color="" floating>
    <router-link to="/" ><MDBIcon icon="home" style="color: #c9011a;"></MDBIcon></router-link>
  </MDBBtn>

  

  <MDBBtn color="" floating>
    <router-link :to="{ name: 'CreateGallery'}" style="color: #c9011a;" ><MDBIcon icon="plus" style="color: #b6c199;"></MDBIcon></router-link>
  </MDBBtn>

   <MDBBtn color="" floating>
    <router-link to="/" style="color: #c9011a;" ><MDBIcon icon="search" style="color: #c9011a;"></MDBIcon></router-link>
  </MDBBtn> -->

  <!-- <a class="m-1" href="#!" role="button">
    <MDBIcon iconStyle="fab" style="color: #1DA1F2;" icon="twitter" size="lg"></MDBIcon>
  </a> -->

  <!-- <router-link to="/" class="btn btn-sm" style="background-color:#b6c199; color:white; padding:5px; opacity:0.8;" >test</router-link>
 <router-link  class="btn btn-sm" style="background-color:#b6c199; color:white; padding:5px; opacity:0.8;" :to="{ name: 'CreateGallery'}">Create</router-link>
 <router-link  class="btn btn-sm" style="background-color:#b6c199; color:white; padding:5px; opacity:0.8;" to="/" >Explore</router-link> -->



 
</template>


<script>
import useLogout from '@/composables/useLogout'
import getUser from '@/composables/getUser'
import {useRouter} from 'vue-router'

 import {    MDBBtn,
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBCollapse,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem, MDBIcon } from "mdb-vue-ui-kit";
  import { ref } from 'vue';

export default{
    components: {
                                                MDBBtn,
      MDBNavbar,
      MDBNavbarToggler,
      MDBNavbarBrand,
      MDBNavbarNav,
      MDBNavbarItem,
      MDBCollapse,
      MDBDropdown,
      MDBDropdownToggle,
      MDBDropdownMenu,
      MDBDropdownItem, MDBIcon
                                                },

    setup() {
        const { logout } = useLogout()
        const { user } = getUser()
        const router = useRouter()

        // const dropdown3 = ref(false);
        const collapse1 = ref(false);
      const dropdown1 = ref(false);
        
            
        const handleClick = async () => {            
            await logout()
            console.log('user logged out')
            router.push({ name: 'Login'})
            closeNav;
            //alert('trying to logout')
        }

      const ngalleryURL = window.location.href;
       
              /* Set the width of the sidebar to 250px and the left margin of the page content to 250px */
        const openNav = async () => { 
          document.getElementById("mySidebar").style.width = "250px";
         // document.getElementById("main").style.marginLeft = "250px";
        }

        /* Set the width of the sidebar to 0 and the left margin of the page content to 0 */
        const closeNav = async () => { 
          document.getElementById("mySidebar").style.width = "0";
          //document.getElementById("main").style.marginLeft = "0";
        }

        return {handleClick, user, collapse1,
        dropdown1, ngalleryURL, openNav, closeNav }


    }

}
</script>

<style scoped>



.myNavBar{
    /* padding: 0px 0px;
    margin-bottom: 0px !important; */
    width:100%;
    position:fixed !important;
        align-items: center;    
    text-align: center;
    z-index: 1999;
    /* background: white; */
    /* opacity: 0.5;
    position:static; */
    /* align-items: center;    
    text-align: center; */
    
}

.floatingbutton{
  top:1%;
   margin: 5px 5px 5px 5px !important;
  opacity:1;
  /* text-shadow: 1px 1px 0px #000;  */
}

.responsiveText {
    font-size: 0.8rem;
}



button, .btn {
    background:#b6c199;
    color:black;
    font-family: 'Nunito', sans-serif !important;
    border-radius: 4px;
    border: 0;
    padding: 2px 8px !important;
    font-weight: 600;
    cursor: pointer;
    display: inline-block;
    text-transform: capitalize !important;
    
}

button:hover, .btn:hover {
    background: #ebebeb;
    color: white;
}

/* .navbar{
    padding: 16px 10px;
    margin-bottom: 20px;
    background: white;
}
nav{
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}
nav h1 {
    margin-left: 20px;
}
nav .links {
    margin-left: auto;
}
nav .links a, button {
    margin-left: 16px;
    font-size: 14px;
}
nav img{
    max-height: 60px;
}

span {
    font-size: 14px;
    display: inline-block;
    margin-left: 16px;
    padding-left: 16px;
    border-left: 1px solid #eee;
} */


/* CSS for side bar */

/* The sidebar menu */
.sidebar {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 2001; /* Stay on top */
  top: 0;
  left: 0;
  background-color: #b6c199;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.25s; /* 0.5 second transition effect to slide in the sidebar */
}

/* The sidebar links */
.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 20px;
  color: black;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidebar a:hover {
  color: #818181;
}

/* Position and style the close button (top right corner) */
.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 0px;
  font-size: 36px;
  margin-left: 50px;
}

/* The button used to open the sidebar */
.openbtn {
  /* font-size: 8px; */
  font-size: 0.8rem;
  cursor: pointer;
  background-color: #b6c199;
  color: black;
  padding: 10px 15px;
  border: none;
  position: fixed !important;
  top: 1%;
  left: -5%;
  z-index: 1999;
}

.openbtn:hover {
  background-color: #444;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  /* If you want a transition effect */
  transition: margin-left 0.5s; 
  padding: 0px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidebar {padding-top: 15px;}
  .sidebar a {font-size: 18px;}
}


</style>