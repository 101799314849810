<template>
  <div class="home container" >
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <router-link :to=" {name: 'CreateGallery'} " class="btn bg-white">Create a new Gallery</router-link> -->
    
     <div class="textsection">
          <br><br><h2>Upgrade to Premium!</h2>

            <div class="extext">
              <b style="color:#da0f41">Your account currently:</b>
            <p>You can <b>create an unlimited amount of galleries</b> and add 15 images per gallery with your free account.</p> <br>
            
            <b style="color:#da0f41">If you upgrade:</b>
            <p>With an upgraded account ($4.99 month), premium features include:<br>
                <ul>
                    <li>30 images per gallery (vs 15)</li>
                    <li>Premium galleries, perfect to align to your tastes and the occasion</li>
                    <li>More features added all the time!</li>
                </ul>
            </p> 
        </div>
        <!-- <p>Subscription opt-in being built,  please check back soon!</p> -->
        <button class="btn btn-sm" onclick="alert('Appreciate your interest! Finishing touches are being put onto the subscription opt-in, please check back soon!')">Upgrade!</button>
     </div>
  </div>

</template>

<script>
import ListView from '../components/ListView.vue'
import getCollection from '../composables/getCollection'




export default {
  name: 'Home',
  components: {ListView},
  setup() {
    const { error, documents } = getCollection('galleries')
    // console.log('home')
    // console.log(documents)

    return {error, documents}
  }
}
</script>

<style scoped>
  button, .btn {
    background:#da0f41;
    color:white;
    font-family: 'Nunito', sans-serif !important;
    border-radius: 8px;
    border: 0;
    padding: 2px 8px !important;
    font-weight: 600;
    cursor: pointer;
    /* display: inline-block; */
    /* display: flex; */
    /* justify-content: center;
    align-items: center; */
    
}
</style>
