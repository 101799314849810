import { ref } from 'vue'
import { projectFirestore,  updateDoc, arrayUnion, FieldValue } from '../firebase/config'



const useDocumentAddFave = (collection, id) => {
    const error = ref(null)
    const isPending = ref(false)
    //alert(collection)
    //alert(id)

  

    


    const updateFaves = async (id, updates) => {
        //alert(updates)
        error.value = null
        // var messageRef = db.collection('rooms').doc('roomA')
        //         .collection('messages').doc('message1');
        
        // working, sort of
        //let docRef = projectFirestore.collection(collection).doc(id).collection('faves')

        // let docRef = projectFirestore.collection(collection).doc(id)

        //alert('hello id')
        // alert(id)
        //alert(updates)

        try{
            // working, sort of

            // var n = updates.lastIndexOf('=');
            // var myid = updates.substring(n + 1);
            // alert(n)
            // alert(myid)
            // let docRef = projectFirestore.collection(collection).doc(id).collection('faves').doc(myid)

            // const res = await docRef.set({url:updates})

            //var n = updates.lastIndexOf('=');
            //var myid = updates.substring(n + 1);

            //alert('test')

            let docRef = projectFirestore.collection('people').doc(id)
            

            // if (docRef) {
            //     alert('collection already created')
            // } else {
            //     alert('collection not')
            // }
            const res = await docRef.update(updates)

            return res
            
    

             

            //isPending.value = false            
            
        } catch (err) {
            console.log(err.message)
            isPending.value = false
            error.value = "document could not be updated"
        }
    }

   

    return {error, isPending, updateFaves}
}

export default useDocumentAddFave