<template>
  <div class="home container" >
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <router-link :to=" {name: 'CreateGallery'} " class="btn bg-white">Create a new Gallery</router-link> -->
     <div class="textsection">
    
        <br><br><h2><span style="color:#da0f41;">Contact</span></h2>

            <div class="extext">
            <p><a href="mailto:pete.instant.gallery@gmail.com">Send email</a></p>
            <!-- TODO create firebase message store -->
          </div>
    </div>
  </div>
</template>

<script>
import ListView from '../components/ListView.vue'
import getCollection from '../composables/getCollection'




export default {
  name: 'Home',
  components: {ListView},
  setup() {
    const { error, documents } = getCollection('galleries')
    // console.log('home')
    // console.log(documents)

    return {error, documents}
  }
}
</script>

<style >
  
</style>
