
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

// import { FieldValue } from 'firebase-admin'
// FieldValue = require('firebase-admin').firestore.FieldValue;



//import { arrayUnion } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBET1llxyuSrGK3N3fVQy0eS2s3203pfSA",
  authDomain: "sportsman-gallery.firebaseapp.com",
  projectId: "sportsman-gallery",
  storageBucket: "sportsman-gallery.appspot.com",
  messagingSenderId: "133372450889",
  appId: "1:133372450889:web:d301b172c05b4ceee38798"
  };


  //init firebase
  firebase.initializeApp(firebaseConfig)

  //init services
  const projectFirestore = firebase.firestore()
  // const arrayUnion = firebase.firestore()
  const FieldValue = firebase.firestore.FieldValue
  // const updateDoc = firebase.firestore()
  const projectAuth = firebase.auth()
  const projectStorage = firebase.storage()

  const deleteUser = firebase.auth()

  //timestamp
  const timestamp = firebase.firestore.FieldValue.serverTimestamp

  export { projectFirestore, projectAuth, timestamp, projectStorage, FieldValue, firebase, deleteUser}


  //////////*********** */
  // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyBET1llxyuSrGK3N3fVQy0eS2s3203pfSA",
//   authDomain: "sportsman-gallery.firebaseapp.com",
//   projectId: "sportsman-gallery",
//   storageBucket: "sportsman-gallery.appspot.com",
//   messagingSenderId: "133372450889",
//   appId: "1:133372450889:web:d301b172c05b4ceee38798"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
