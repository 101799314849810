import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { projectAuth } from './firebase/config' 

//global styles
import './assets/main.css'
import 'mdb-vue-ui-kit/css/mdb.min.css';

// require('aframe')






let app

projectAuth.onAuthStateChanged(() => {
    if(!app){
        app = createApp(App).use(router).mount('#app')
        console.log('sportsman pete')
        // app.config.compilerOptions.isCustomElement = (tag) => {
        //     return tag.startsWith('a-')
        //   }
     
    }
    
})


  

if(app){
    console.log('app creatd')
    console.log(app.config)

   
}





