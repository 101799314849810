import { ref, watchEffect } from 'vue'
import { projectFirestore } from '../firebase/config'

const getDocument = (collection, id) => {
  // alert('trying to get document')
  // alert(collection)
  // alert(id)

  const document = ref(null)
  const error = ref(null)
  const readyToUpdate = ref(null)

  // register the firestore collection reference
  let documentRef = projectFirestore.collection(collection).doc(id)
  //alert('documentref is: ' + documentRef)


  const unsub = documentRef.onSnapshot(doc => {
    if(doc.data()){
        //alert('datafound')
        document.value = {...doc.data(), id:doc.id}           
        console.log("gallery loaded or updated!!!")
        error.value = null
    }else{
      //alert('data not found')
      error.value = 'that document does not exist'
    } 
    
  }, err => {
    console.log(err.message)
    error.value = 'could not fetch the data'
  })


  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  });
 
  return { error, document }
}

export default getDocument