<template>
    
    <div class="user-galleries container">
        <br><br>
        <h2>Galleries You Created</h2>
        <div v-if="galleries">
            <ListView :galleries="galleries" />
        </div>
        <router-link :to=" {name: 'CreateGallery'} " class="btn btn-primary">Create a new Gallery</router-link>
        <hr>
         <!-- <h2>Creators You Follow</h2> -->
         
    </div>
</template>

<script>
import getUser from '@/composables/getUser'

import getCollection from '@/composables/getCollection'
import ListView from '@/components/ListView.vue'


// import getDocumentOnce from '@/composables/getDocumentOnce'
// import useCollection from '@/composables/useCollection';


// import useStorage from '@/composables/useStorage'
// import useDocument from '@/composables/useDocument'
// import useDocumentAddFave from '@/composables/useDocumentAddFave'
// import { projectFirestore } from '../firebase/config'


export default {
    components:{ ListView },
    setup(){
        const { user } = getUser()
        //const {peopleerror, addPerson} = useCollection('people')

        console.log(user.value.uid)

        const { documents: galleries } = getCollection(
            'galleries',
            ['userId', '==', user.value.uid]
        )

        
        //  const {error, document: people } = getDocumentOnce('people', user.value.uid)
        //  console.log(error.value)


        //const {peopleerror, addPerson} = useCollection('people')
  
        //alert(user.value.uid)
        //console.log('people exist: ' + people)

        // if(error.value){
        //     alert('error found 1')
        // } else {
        //     alert('NO error found 1')
        // }

        // if (error){
        //     alert('error returned')
        //     alert('people record not yet created, creating now')
        //             const res = addPerson({
        //                 userId: user.value.uid,
        //                 userName: user.value.displayName,
        //                 following:[],
        //                 faves:[],
        //                 galleries:[]
        //                 })
        // }

        
        // if (people == null){
        //     alert('people record not yet created, creating now')
        //             const res = addPerson({
        //                 userId: user.value.uid,
        //                 userName: user.value.displayName,
        //                 following:[],
        //                 faves:[],
        //                 galleries:[]
        //                 })
        //             //handleCreatePeople()
                  
        // } else {
        //     //alert('people record already created')
        // }
        

  

      
    
        
        
        //console.log(galleries.value)
        

        return {galleries}
    }
}
</script>

<style>

</style>