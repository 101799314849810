import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'

const useCollection = (collection) => {

  const error = ref(null)
  const isPending = ref(false)
  const peopleerror = ref(null)

  // add a new document
  const addDoc = async (doc) => {
    //alert('trying to create gallery!')
    error.value = null
    isPending.value = true

    try {
      // const res = await projectFirestore.collection(collection).add(doc)
      const res = await projectFirestore.collection(collection).doc(doc.userName + '-' + doc.title).set(doc)
      isPending.value = false
      return res
    }
    catch(err) {
      console.log(err.message)
      error.value = 'could not send the message'
      isPending.value = false
    }
  }


  const addPerson = async (doc) => {
    console.log('trying to add new person')
    peopleerror.value = null
    isPending.value = true
    var newperson = doc.userId
    try {
      //const res = await projectFirestore.collection(collection).add(doc)
      // db.collection('users').doc(user_id).set({foo:'bar'}, {merge: true})

    


            const res = await projectFirestore.collection(collection).doc(doc.userId).set(doc, {merge: true})
            isPending.value = false
            return res

      
    }
    catch(err) {
      console.log(err.message)
      peopleerror.value = 'error creating account'
      isPending.value = false
    }
  }

  return { error, addDoc, addPerson, isPending, peopleerror }

}

export default useCollection