<template>
  <div class="home container" >
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <router-link :to=" {name: 'CreateGallery'} " class="btn bg-white">Create a new Gallery</router-link> -->
    <br><br>

         <div class="textsection">
          <h1> <span style="color:#da0f41;">Account Management</span></h1> <br>

          <div v-if="!premiumQueryReturned"> <p>Retreiving account details</p>
            <div class="spinner-grow text-danger" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

            <!-- NON PREMIUM USER -->
            <div v-if="premiumQueryReturned && !isPremium">
                            <div class="extext" >
                                  <b >Your account currently is a: <span style="color:#da0f41">Standard Account </span></b>   <button v-if="!isPending" class="btn btn-sm" @click="createSubscription">Upgrade Now!</button>

                              <button v-if="isPending" class="btn btn-sm" @click="createSubscription">Loading payment options...</button>
                                <p>With your standard free account you can <b>create an unlimited amount of galleries</b> and add 15 images per gallery.</p> 
                                
                                <b style="color:#da0f41">You should upgrade to a Premium Account!</b>
                                <p>With an upgraded account ($4.99 month), premium features include:<br>
                                    <ul>
                                        <li>30 images per gallery (vs 15) </li>
                                        <li>Premium galleries, perfect to align to your tastes and the occasion such as (click for examples)<ul><li>
                                          <a href="https://instant.gallery/g/Peter%20Rogers-Wedding%20day" target="blank" >wedding gallery design</a> </li>
                                          <li><a href="https://instant.gallery/g/Emily%20Gomes-James%E2%80%99%20birthday%20" target="blank" >birthday celebrations</a></li>
                                          <li><a href="https://instant.gallery/g/Peter%20Rogers-Neon" target="blank" >80s neon party theme</a></li>
                                          <li><a href="https://instant.gallery/g/Emily%20Gomes-Jubilee" target="blank" >classic ornate</a></li>
                                          </ul><br> <img width="250px" src="/images/screenshots1.webp" /></li>
                                        <li>Additional positional audio played in your gallery as the soundtrack to your memories</li>
                                        <li>More features added all the time! (new gallery layouts are being design for: summer vacation, halloween, Christmas, and a retail experience - all expected by Q3 2022)</li>
                                        <!-- <button v-if="!isPending" class="btn btn-sm" @click="createSubscription">Upgrade Now!</button> -->
                                    </ul> 
                                </p> 
                            </div>


                        <!-- <p>Subscription opt-in being built,  please check back soon!</p> -->
                          <button v-if="!isPending" class="btn btn-sm" @click="createSubscription">Upgrade Now!</button>

                          <button v-if="isPending" class="btn btn-sm" @click="createSubscription">Loading payment options...</button>

                          <br><br>
                          <p><b>Note:</b> You can cancel a premium subscription at any time (we make it as easy to cancel a sunscription as it is to start!!). Any galleries created while you have a premium account keep all premium features.</p>

                            <!-- <button v-if="!isPendingAccountMgmt" class="btn btn-sm" @click="accessStripeAccount">Payment Portal</button> -->
                            <!-- <p v-if="isPendingAccountMgmt">Loading account management interface...</p> -->
                              <!-- <div  class="spinner-grow text-danger" role="status">
                                <span class="sr-only">Loading...</span>
                              </div> -->
                            <!-- <button v-if="isPendingAccountMgmt" class="btn btn-sm" @click="accessStripeAccount"> <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading... account management interface...</button> -->

            </div>
            <!-- //end of non premium -->


                      <!-- NON PREMIUM USER -->
            <div v-if="premiumQueryReturned && isPremium">
                            <div class="extext" >
                                  <b >Your account is a: <span style="color:#da0f41">Premium Account </span></b>

                                <p>With your upgraded account, premium features include:<br>
                                    <ul>
                                        <li>30 images per gallery (vs 15) </li>
                                        <li>Premium galleries, perfect to align to your tastes and the occasion such as (click for examples)<ul><li>
                                          <a href="https://instant.gallery/g/Peter%20Rogers-Wedding%20day" target="blank" >wedding gallery design</a> </li>
                                          <li><a href="https://instant.gallery/g/Emily%20Gomes-James%E2%80%99%20birthday%20" target="blank" >birthday celebrations</a></li>
                                          <li><a href="https://instant.gallery/g/Peter%20Rogers-Neon" target="blank" >80s neon party theme</a></li>
                                          <li><a href="https://instant.gallery/g/Emily%20Gomes-Jubilee" target="blank" >classic ornate</a></li>
                                          </ul><br> <img width="250px" src="/images/screenshots1.webp" /></li>
                                        <li>Additional positional audio played in your gallery as the soundtrack to your memories</li>
                                        <li>More features added all the time! (new gallery layouts are being design for summer vacation, halloween, Christmas, and a retail experience - all expected by Q3 2022) </li>
                                        <!-- <button v-if="!isPending" class="btn btn-sm" @click="createSubscription">Upgrade Now!</button> -->
                                    </ul>  
                                    <button v-if="!isPendingAccountMgmt" class="btn btn-sm" @click="accessStripeAccount">Manage payment and subscription details</button>
                                    <button v-if="isPendingAccountMgmt" class="btn btn-sm" @click="accessStripeAccount"> <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading... account management interface...</button>
                                </p> 
                            </div>


                        <!-- <p>Subscription opt-in being built,  please check back soon!</p> -->
                          

                         <p><b>Note:</b> You can cancel a premium subscription at any time (we make it as easy to cancel a sunscription as it is to start!!). Any galleries created while you have a premium account keep all premium features.</p>

                            <!-- <button v-if="!isPendingAccountMgmt" class="btn btn-sm" @click="accessStripeAccount">Payment Portal</button> -->
                            <!-- <p v-if="isPendingAccountMgmt">Loading account management interface...</p> -->
                              <!-- <div  class="spinner-grow text-danger" role="status">
                                <span class="sr-only">Loading...</span>
                              </div> -->
                           

            </div>
            <!-- //end of non premium -->
        
      

        <div v-if="checkUserIsPremium"> </div>
        <div v-if="!checkUserIsPremium"> </div>

         <div v-if="isPremium"> </div>
        <div v-if="!isPremium">  </div>




                 <hr><br>
                 <b><span style="color:#da0f41">Delete Account</span></b> 
                <div v-if="!deleteAccount1" >
                 <p>Deleting your account will remove your galleries and uploaded images.</p>
                  <button class="btn btn-sm" @click="deleteAccount">Delete Account</button>
                </div>

                 <div v-if="deleteAccount1" >
                   <p> We are sorry to see you go. <b>This can't be undone</b> (though you are always welcome to create a new account) - if you're sure you want to delete your account, galleries and images, click with care!</p>
                  <button type="button" @click="deleteAccountYes" class="btn btn-warning">Yes, delete my account, galleries and images</button>
                  <button type="button" @click="deleteAccountNo" class="btn ">Cancel</button>
                 </div>


     </div>

  

   
    
      <!-- The galleries you create can be shared easily - no account needed to view your collections. -->
          <!-- <div class="ui icon input" style="width: 100%">
              <input type="text" placeholder="Search..." v-model="searchQuery" />
              <i class="search icon"></i>
          </div> -->

          <!-- <div
              class="card ui fluid"
              v-for="product in searchedProducts"
              :key="product.id"
              style="margin: 0"
            >
              ...
            </div> -->
    
    <!-- <br><br><h2>Popular Galleries</h2>
    <div v-if="error" class="error">Could not fetch the data</div>
    <div v-if="galleries">
       <ListView :galleries="galleries" />
      
    </div><hr> -->

       
      <!-- <div v-if="galleries && user"><br>
        <h2>Galleries You Created</h2>
            <ListView v-if="usergalleries" :galleries="usergalleries" />
        </div> -->
         <!-- <div v-if="!user">
            <p>Signup instantly for a free acount and get creating!!</p>
        </div>
          <div v-if="user && !usergalleries">
            <p>User found, but no galleries</p>
        </div> -->
        <!-- <hr>

     <br><h2>New Galleries</h2>
    <div v-if="error" class="error">Could not fetch the data</div>
    <div v-if="newgalleries">
       <ListView :galleries="newgalleries" />
      
    </div> -->

     <!-- <br><br><h2>Popular Creators</h2>
     <div v-if="documents">
      <PeopleView :people="documents"/>
    </div> -->


  </div>
</template>

<script>
import getUser from '@/composables/getUser'
//import getCollection from '../composables/getCollection'

import ListView from '../components/ListView.vue'

import { ref, watchEffect } from 'vue'
import { projectFirestore, deleteUser } from '../firebase/config'

//import firebase from "../firebase"
// import PriceSelection from "@/components/PriceSelection"
import firebase from 'firebase/app'

import {useRouter } from 'vue-router'
import { computed } from '@vue/reactivity'

import useLogout from '@/composables/useLogout'
// import { ref } from 'vue'
// import { computed } from '@vue/reactivity'


export default {
  name: 'Home',
  components: {ListView},
  setup() {
    // const { error, documents } = getCollection('galleries')
      const { user } = getUser()
      const isPending = ref(false)
      const isPendingAccountMgmt = ref(false)

      const deleteAccount1 = ref(false)
      const deleteAccount2 = ref(false)

       const router = useRouter()

       const isPremium = ref(false)
       const premiumQueryReturned = ref(false)

       const { logout } = useLogout()
      //alert(user.value.uid)

      // projectFirestore.collection('products')
      //     .where('active', '==', true)
      //     .get()
      //     .then(function (querySnapshot) {
      //       querySnapshot.forEach(async function (doc) {
      //         console.log(doc.id, ' => ', doc.data());
      //         const priceSnap = await doc.ref.collection('prices').get();
      //         priceSnap.docs.forEach((doc) => {
      //           console.log(doc.id, ' => ', doc.data());
      //         });
      //       });
      //     });

      const createSubscription = async (doc) => {
        isPending.value = true

                      const docRef = await projectFirestore
                          .collection('people')
                          .doc(user.value.uid)
                          .collection('checkout_sessions')
                          .add({
                            price: 'price_1LFOt2HEkUUNQu3qhNPu4gy6',
                            success_url: window.location.origin,
                            cancel_url: window.location.origin,
                          });
                        // Wait for the CheckoutSession to get attached by the extension
                        docRef.onSnapshot((snap) => {
                          const { error, url } = snap.data();
                          if (error) {
                            // Show an error to your customer and
                            // inspect your Cloud Function logs in the Firebase console.
                            alert(`An error occured: ${error.message}`);
                          }
                          if (url) {
                            // We have a Stripe Checkout URL, let's redirect.
                            window.location.assign(url);
                          }
                        });
      }

      const accessStripeAccount = async () => {
        //alert('hello')
        isPendingAccountMgmt.value = true
        
          const functionRef = firebase
              .app()
              .functions('us-central1')
              .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
            const { data } = await functionRef({
              returnUrl: window.location.origin,
              locale: "auto", // Optional, defaults to "auto"
              // configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
            });
            window.location.assign(data.url);

      }


      


      const checkUserIsPremium = computed (() => {
      
          
          projectFirestore.collection('people')
            .doc(user.value.uid)
            .collection('subscriptions')
            .where('status', 'in', ['trialing', 'active'])
            .onSnapshot(async (snapshot) => {
              // In this implementation we only expect one active or trialing subscription to exist.
              const doc = snapshot.docs[0];
              //console.log(doc.id, ' => ', doc.data());
              if(doc){
                  //alert("premium exisists!")
                  premiumQueryReturned.value = true 
                  isPremium.value = true;
                  return true
              } else {
                    //alert("premium  doesnay exisists!")
                    premiumQueryReturned.value = true 
                    isPremium.value = false;
                    return false
              }
              
            });

      })


      const deleteAccount = async () => {
        deleteAccount1.value = true
      }


        const deleteAccountYes = () => {
             projectFirestore.collection("users").doc(user.value.uid).delete()
            // await projectFirestore.collection("users").doc(user).delete()
            // await firebase.auth().currentUser.delete()
            // alert('trying to delete: ' + user.value.uid)
            // projectFirestore.user.delete().then(() => {
            //   // User deleted.
            //   alert('trying to delete1')
            // }).catch((error) => {
            //   // An error ocurred
            //   // ...
            // });
            // projectFirestore.collection("users").deleteUser(user.value.uid).then(() => {
            //   // User deleted.
            //    alert('trying to delete1')
            // }).catch((error) => {
            //   // An error ocurred
            //   // ...
            // });
            //   console.log(user)
            // projectFirestore.collection("users").doc(user.value.uid).delete()
            //   .then(() => {
            //     alert('Deleted')
            //   })
            logout()
            console.log('user logged out')
            router.push({name:'Home'})
        }

      const deleteAccountNo = async () => {
        deleteAccount1.value = false
      }

    



      // if (user.value != null){
      //      var { documents: usergalleries } = getCollection(
      //       'galleries',
      //       ['userId', '==', user.value.uid],
      //       'thisusergalleries'
      //   )
      // }
            //console.log('test user')
            //console.log(user.value.uid)


      // const { documents: galleries } = getCollection(
      //       'galleries',
      //       ['sharingOptions', '==', 'discoverable'],
      //       'popular'
      //   )




      // const searchQuery = ref("");

      // const searchedProducts = computed(() => {
      //     return galleries.value.filter((product) => {
      //       return (
      //         product.title
      //           .toLowerCase()
      //           .indexOf(searchQuery.value.toLowerCase()) != -1
      //       );
      //     });
      // });
     


        //  const { documents: newgalleries } = getCollection(
        //     'galleries',
        //     ['sharingOptions', '==', 'discoverable'],
        //     'new'
        // )
    // console.log('home')
    // console.log(documents)

    return {user, createSubscription, accessStripeAccount,  isPending, isPendingAccountMgmt, deleteAccount, deleteAccountYes, deleteAccountNo, deleteAccount1, deleteAccount2, checkUserIsPremium, isPremium, premiumQueryReturned }
  }
}
</script>

<style scoped>

.btn, .btn-sm{
  margin: 5px !important;
}
  
</style>
