import { ref, watchEffect } from 'vue'
import { projectFirestore } from '../firebase/config'

const getPeopleFaves = (collection, id) => {

  const document1 = ref(null)
  const tempDoc = ref([])
  const errorpeople = ref(null)
  const readyToUpdate = ref(null)

  // register the firestore collection reference
  let documentRef = projectFirestore.collection(collection).doc(id)

//   documentRef.get().then((querySnapshot) => {
//     // const tempDoc = []
//     querySnapshot.forEach((doc) => {
//         // doc.data() is never undefined for query doc snapshots
//         const tempDoc = []
//         console.log("people data loaded!!!")
//         console.log(doc.id, " => ", doc.data());
//         tempDoc.push({ id: doc.id, ...doc.data() })
//     });
//     console.log(tempDoc[0].value)
// });


  const unsub = documentRef.onSnapshot(doc => {
    if(doc.data()){
        document1.value = {...doc.data(), id:doc.id}           
        console.log("people data loaded or updated(probably added to faves)!!!")
        errorpeople.value = null
    }else{
        errorpeople.value = 'that document does not exist'
    } 
    
  }, err => {
    console.log(err.message)
      errorpeople.value = 'could not fetch the data'
  })


  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  });
 
  return { errorpeople, document1 }
}

export default getPeopleFaves