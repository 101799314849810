import { ref } from 'vue'
import { projectFirestore, FieldValue } from '../firebase/config'
// const FieldValue = require('firebase-admin').firestore.FieldValue;

const useDocument = (collection, id) => {
    const error = ref(null)
    const isPending = ref(false)
    //alert(collection)
    //alert(id)

    let docRef = projectFirestore.collection(collection).doc(id)
  

    const deleteDoc = async () => {
        isPending.value = true
        error.value = null
        

        try{
            const res = await docRef.delete()
            isPending.value = false            
            return res
        } catch (err) {
            console.log(err.message)
            isPending.value = false
            error.value = "Gallery could not be deleted."
        }
    }

    const updateDoc = async (updates) => {
        isPending.value = true
        error.value = null
        

        try{
            const res = await docRef.update(updates)
            isPending.value = false            
            return res
        } catch (err) {
            console.log(err.message)
            isPending.value = false
            error.value = "document could not be updated"
        }
    }

    const incrementLikes = async (updates) => {
        isPending.value = true
        error.value = null
        

        try{
            const res = await docRef.update({totallikes: FieldValue.increment(1)});
            
            isPending.value = false            
            return res
        } catch (err) {
            console.log(err.message)
            isPending.value = false
            error.value = "document could not be updated"
        }
    }

    const incrementHomePageView = async (updates) => {
        console.log('trying to add to page views')
        isPending.value = true
        error.value = null
        

        try{
            const res = await docRef.update({views: FieldValue.increment(1)});
            
            isPending.value = false            
            return res
        } catch (err) {
            console.log(err.message)
            isPending.value = false
            error.value = "document could not be updated"
        }
    }

    // const updateFaves = async (updates) => {
    //     alert(updates)
    //     error.value = null
        

    //     try{
    //         const res = await docRef.set(updates)
    //         //isPending.value = false            
    //         return res
    //     } catch (err) {
    //         console.log(err.message)
    //         isPending.value = false
    //         error.value = "document could not be updated"
    //     }
    // }

   

    return {error, isPending, deleteDoc, updateDoc, incrementLikes, incrementHomePageView}
}

export default useDocument