<template>
<br><br>
  <form @submit.prevent="handleSubmit">
      <h3>Sign up</h3>
      <input type="text" placeholder="Display name" v-model="displayName">
      <input type="email" placeholder="email" v-model="email">
      <input type="password" placeholder="password" v-model="password">
      <div v-if="error" class="error">{{ error }}</div>
      <button v-if="!isPending">Sign up</button>
      <button v-if="isPending" disabled>Loading..</button>

      <!-- <br><br><p><strong>OR</strong> sign in with:</p> -->

        
  </form><br>

    
   <div class="textsection" style="max-width:400px">
          <p><strong>OR</strong> sign in with:</p>
           
            <img style="cursor:pointer;" @click="socialLogin" src="/images/glogo1.png" alt="Sign in with Google logo">
     
      </div>

      <!-- <form  @submit.prevent="handleSubmit" id="customform" >
        <h4>Image Limit Reached!!!</h4>
        <p>You have reached the 15 image limit for this gallery. Either upgrade to a premium account which allows you to add 30 images per gallery. Or simply a create a new gallery and add your images there (premium account not needed). </p>
          <button class="btn btn-primary">Upgrade to Premium</button> 
          <button class="btn" @click="maxLimitReached=false">Cancel</button> 
      </form> -->

  <!-- <button @click="socialLogin">
            <img src="/images/glogo1.png" alt="Sign in with Google logo">
        </button> -->
</template>

<script>
import useSignup from '@/composables/useSignup'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

import firebase from 'firebase';
// import { timestamp } from '@/firebase/config'

import useCollection from '@/composables/useCollection';
import getUser from '@/composables/getUser'




export default {
    setup(){
       const{error, signup, isPending} = useSignup()
       const router = useRouter()
       const email = ref('')
       const password = ref('')
       const displayName = ref('')
       const {peopleerror, addPerson} = useCollection('people')

       const { user } = getUser()

       const handleSubmit = async () => {
           const res = await signup ( email.value,  password.value, displayName.value)
           if(!error.value){
               handleCreatePeople()
               //router.push( {name: 'UserGalleries'} )
           }
       }

        const socialLogin = async () => {
           const provider = new firebase.auth.GoogleAuthProvider();

            // firebase.auth().signInWithPopup(provider).then((result) => {
            //    handleCreatePeople();
            //  })

            const response = await firebase.auth().signInWithPopup(provider)
            handleCreatePeople();


            
                
       }


       const handleCreatePeople = async () => {
           //alert('hello!')
           
           //alert(user.value)

        //    if (user) {
        //     // proceed
        //         console.log('test:')
        //         console.log(user.vale)
        //         // alert(user.value.uid)
        //     }

        

               
         
                const res = await addPerson({
                    
                    userId: user.value.uid,
                    userName: displayName.value,
                    following:[],
                    faves:[],
                    galleries:[]
                })
                //isPending.value = false
                if(!peopleerror.value){
                    router.push({ name: 'UserGalleries'})
                } else{
                    console.log('error!!!1')
                }
                
                
                //console.log("image uploaded, url:", url.value)
                //console.log(title.value, description.value, file.value)
            
            
        }

       return {email, password, displayName, handleSubmit, handleCreatePeople,  socialLogin, error, isPending}
    }
}
</script>

<style>

</style>