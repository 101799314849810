import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Upgrade from '../views/Upgrade.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Login from '../views/auth/Login.vue'
import Signup from '../views/auth/Signup.vue'
import CreateGallery from '../views/galleries/CreateGallery.vue'
import GalleryDetails from '../views/galleries/GalleryDetails.vue'
import UserGalleries from '../views/galleries/UserGalleries.vue'
import AccountPage from '../views/AccountPage.vue'
//route guard
import { projectAuth } from '../firebase/config'

const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser
  if (!user){
    console.log("no user")
    next({ name: 'Login' })
  } else {
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // beforeEnter: requireAuth
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    // path: '/galleries/create',
    path: '/create',
    name: 'CreateGallery',
    component: CreateGallery,
    beforeEnter: requireAuth
  },
  {
    path: '/g/:id',
    name: 'GalleryDetails',
    component: GalleryDetails,
    props: true
    // beforeEnter: requireAuth

  },
  {
    path:'/g/user',
    name:'UserGalleries',
    component: UserGalleries,
    beforeEnter: requireAuth
  },
  {
    path: '/upgrade',
    name: 'Upgrade',
    component: Upgrade
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/account',
    name: 'AccountPage',
    component: AccountPage
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
