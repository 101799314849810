<template>
<br><br>



   <form @submit.prevent="handleSubmit">
          <div v-if="!premiumQueryReturned && checkUserIsPremium || !premiumQueryReturned && !checkUserIsPremium "> <p>Retreiving account details</p>
                    <div class="spinner-grow text-danger" role="status">
                    <span class="sr-only">Loading...</span>
                    </div>
            </div>

       <h4>Create New Gallery</h4>

        <!-- //IF FOR PREMIUM USERS -->
        <div v-if="isPremium && premiumQueryReturned" >
                                <input type="text" required :autofocus="'autofocus'" placeholder="Gallery name" v-model="title" maxlength="40">
                                <textarea placeholder="Gallery description..." v-model="description" maxlength="100"></textarea>

                                
                                <div class="myselect" >
                                    <br><br><br>
                                    <p>Gallery Style</p>
                                        <select id="galleryScheme" name="galleryScheme" form="galleryScheme" v-model="galleryScheme" >
                                            <option value="" disabled selected>Bright and Light</option>
                                            <option value="instant">Bright and Light</option>
                                            <!-- <option value="soothing">Soothing Green</option> -->
                                            <option value="moody">Dark and moody</option>
                                        
                                            <optgroup label="Premium:">
                                                <!-- <option  disabled value="birthday">Birthday</option> -->
                                                <option   value="birthday">Birthday</option>
                                                <option   value="wedding">Wedding</option>
                                                <!-- <option  value="retail">Retail</option> -->
                                                <!-- <option  value="summer">Summer Fun</option> -->
                                                <!-- <option  value="christmas">Christmas</option> -->
                                                <!-- <option  value="halloween">Halloween Horror</option> -->
                                                <!-- <option value="fishing">Fishing Retreat</option>
                                                <option value="hunting">Hunting Lodge</option> -->
                                                <option  value="ornate">Traditional Ornate</option>  
                                                <!-- <option  value="lisbon">Lisbon Lobby</option>  -->
                                                <option  value="neon">Neon 80s</option>  
                                                <!-- <option disabled value="sports">Sports Day</option>     -->
                                            </optgroup>
                                        </select>
                                        
                                <br><br><br>    
                                <br><br><br> 
                                    <p>Sharing Options</p>
                                        <select id="sharingOptions" name="sharingOptions" form="sharingOptions" v-model="sharingOptions" >
                                            <option value="" disabled selected>Discoverable</option>
                                            <option value="discoverable">Discoverable</option>
                                            <option value="link">Let anyone with the link see photos</option>
                                            <option value="linkpin">Let anyone with the link and PIN see photos</option>
                                        </select>
                                        <input v-if="sharingOptions=='linkpin'" type="password" required placeholder="Enter 4 digit pin here" v-model="pin" minlength="4" maxlength="4">
                                </div>
                                <!-- //END PREMIUM USERS -->
                                
                                    
                                    
                                <!-- upload gallery image -->
                                <!-- <label> Upload Gallery Welcome Image</label>
                                <input type="file" @change="handleChange"> -->

                                <div class="error">{{ fileError }}</div>


                                <button v-if="!isPending" class="btn btn-primary center">Create New Gallery</button> 
                                <button v-if="isPending" disabled>Saving...</button> 

        </div>


        <!-- ..if user is NOT premium -->
             <div v-if="!isPremium && premiumQueryReturned" >
                                <input type="text" required :autofocus="'autofocus'" placeholder="Gallery name" v-model="title" maxlength="40">
                                <textarea placeholder="Gallery description..." v-model="description" maxlength="100"></textarea>

                                <!-- //END PREMIUM USERS -->
                                
                                    
                                    
                                <!-- upload gallery image -->
                                <!-- <label> Upload Gallery Welcome Image</label>
                                <input type="file" @change="handleChange"> -->

                                <div class="error">{{ fileError }}</div>


                                <button v-if="!isPending" class="btn btn-primary center">Create New Gallery</button> 
                                <button v-if="isPending" disabled>Saving...</button> 

                        </div>
                        <!-- //end of NOT premium user -->


      
    </form>
    <br>

    
    

</template>


<script>
import { ref } from 'vue'

import useStorage from '@/composables/useStorage'
import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'
import { timestamp } from '@/firebase/config'
import { useRouter } from 'vue-router'

import { computed } from '@vue/reactivity'

import { projectFirestore } from '@/firebase/config'
// import firebase from 'firebase/app'




export default {
    setup(){
        const { filePath, url, uploadImage } = useStorage()
        const {error, addDoc} = useCollection('galleries')
        const { user } = getUser()
        const router = useRouter()


        const title = ref('')
        const description = ref('')
         const galleryScheme = ref('')
         const sharingOptions = ref('')
         const pin = ref('')
        const file = ref(null)
        const fileError = ref(null)
        const isPending = ref(false)
        const isPending1 = ref(false)

        //isPremium updates
        const isPremium = ref(false)
        const premiumQueryReturned = ref(false)

            const checkUserIsPremium = computed (() => {
                projectFirestore.collection('people')
                            .doc(user.value.uid)
                            .collection('subscriptions')
                            .where('status', 'in', ['trialing', 'active'])
                            .onSnapshot(async (snapshot) => {
                                // In this implementation we only expect one active or trialing subscription to exist.
                                const doc = snapshot.docs[0];
                                //console.log(doc.id, ' => ', doc.data());
                                if(doc){
                                    //alert("premium exisists!")
                                    premiumQueryReturned.value = true 
                                    isPremium.value = true;
                                    return true
                                } else {
                                        //alert("premium  doesnay exisists!")
                                        premiumQueryReturned.value = true 
                                        isPremium.value = false;
                                        return false
                                }
                           });
            })

                 const createSubscription = async (doc) => {
                        isPending1.value = true

                                    const docRef = await projectFirestore
                                        .collection('people')
                                        .doc(user.value.uid)
                                        .collection('checkout_sessions')
                                        .add({
                                            price: 'price_1LFOt2HEkUUNQu3qhNPu4gy6',
                                            success_url: window.location.origin,
                                            cancel_url: window.location.origin,
                                        });
                                        // Wait for the CheckoutSession to get attached by the extension
                                        docRef.onSnapshot((snap) => {
                                        const { error, url } = snap.data();
                                        if (error) {
                                            // Show an error to your customer and
                                            // inspect your Cloud Function logs in the Firebase console.
                                            alert(`An error occured: ${error.message}`);
                                        }
                                        if (url) {
                                            // We have a Stripe Checkout URL, let's redirect.
                                            window.location.assign(url);
                                        }
                                        });
                    }

        
        const handleSubmit = async () => {
            if(title.value){
                isPending.value = true
                // await uploadImage(file.value)
                if (!galleryScheme.value){                    
                    galleryScheme.value = 'instant'
                }
                //alert(sharingOptions.value)

                 if (!sharingOptions.value){                    
                    sharingOptions.value = 'discoverable'
                    //alert('seeting as discoverable')
                }

                //alert(sharingOptions.value)

                //format displayName and title here to swap 'spaces' for '-'

                // const tempDisplayName = user.value.displayName.replaceAll(' ', '-');
                // const tempTitle = title.value.displayName.replaceAll(' ', '-');


                const res = await addDoc({
                    title: title.value,
                    description: description.value,
                    userId: user.value.uid,
                    userName: user.value.displayName,
                    coverUrl: url.value,
                    filePath: filePath.value,
                    galleryScheme:galleryScheme.value,
                    sharingOptions:sharingOptions.value,
                    pin:pin.value,
                    pics:[],
                    totallikes:0,
                    // pics:[...props.gallery.pics, newImage],
                    createdAt: timestamp()
                })
                //alert(sharingOptions.value)
                isPending.value = false
                if(!error.value){
                    // router.push({ name: 'GalleryDetails', params: {id:res.id}})
                    router.push({ name: 'GalleryDetails', params: {id:user.value.displayName + '-' + title.value}})
                } else{
                    console.log('error!!!1')
                }
                
                
                //console.log("image uploaded, url:", url.value)
                //console.log(title.value, description.value, file.value)
            }
            
        }

        const selected = 'instant'

        //allowed file types
        // const types = ['image/png', 'image/jpeg', 'image/jpg' ]
        var pingallery = false;
         const sharingOptionsClick = (e) => {
             alert(sharingOptions.value)
            // if(sharingOptions.value == "linkpin"){
            //     pingallery = true
            //     alert('hello')
            // }else{ 
            //     pingallery = false
            // }
        }

        const handleChange = (e) => {
            const selected = e.target.files[0]
            console.log(selected)

            if(selected && types.includes(selected.type)){
                file.value = selected
                fileError.value = null
            } else {
                file.value = null
                fileError.value = 'Please select an image file (png or jpg)'
            }
        }

        return {title, description, galleryScheme, sharingOptions, selected, handleSubmit, handleChange, fileError, isPending, isPending1, sharingOptionsClick, pingallery, pin, checkUserIsPremium, isPremium, premiumQueryReturned, createSubscription}
    }
}

</script>

<style scoped>

/* input[type="file"] {
    border: 0;
    padding: 0;
} */

label {
    font-size: 12px;
    display: block;
    margin-top: 50px;
}

button {
    margin-top: 5px;
}

.myselect{
    line-height: 0.5;
}

.extext{
    margin: 30px;
}


  button, .btn {
    background:#b6c199;
    color:white;
    font-family: 'Nunito', sans-serif !important;
    border-radius: 4px;
    border: 0;
    /* padding: 2px 8px !important; */
    font-weight: 600;
    cursor: pointer;
    /* display: inline-block; */
    /* display: flex; */
    /* justify-content: center;
    align-items: center; */
    /* align-items: center !important;
    justify-content: center; */

    
}

.center {
    margin: 0;
  position: relative;
  /* top: 50%; */
  left: 45%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

</style>